import { trackingCode } from './trackingCode';

function initTrackingCode() {
    if (typeof trackingCode !== 'undefined') {
        const element = document.getElementById('cookiemonster-tracking-code');
        const trackingCodeURL = element.getAttribute('data-tracking-code-url');

        trackingCode.init(trackingCodeURL);
    } else {
        console.log('Kein TrackingCode geladen!');
    }
}

if (document.readyState === 'complete') {
    initTrackingCode();
} else {
    console.log('TrackingCode-Initialisierung verlagert, da readyState = ' + document.readyState);
    window.addEventListener('load', initTrackingCode);
}
