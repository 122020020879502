const trackingCode = {
    initialized: false,
    trackingCodeURL: null,

    getSbeKId: function() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('sbekid');
    },

    isKundeCookieAvailable: function() {
        return document.cookie.indexOf('KUNDE=') > -1;
    },

    appendLegacyTokenToSbeKId: function(sbeKId) {
        return btoa(atob(sbeKId) + ':a');
    },

    writeKundeCookie: function(kundeCookie) {
        document.cookie = 'KUNDE=' + kundeCookie + '; path=/;';
    },

    createNewsletterKundenCookie: function() {
        if (this.isKundeCookieAvailable()) {
            return;
        }

        const sbeKId = this.getSbeKId();

        if (!sbeKId) {
            return;
        }

        const kundeCookie = this.appendLegacyTokenToSbeKId(sbeKId);
        this.writeKundeCookie(kundeCookie);
    },

    createImageDOM: function() {
        const image = document.createElement('img');
        image.setAttribute('src', this.trackingCodeURL);
        image.setAttribute('height', '1px');
        image.setAttribute('width', '1px');
        image.setAttribute('alt', '');
        image.setAttribute('style', 'display:none;');

        const body = document.body;
        body.append(image);
    },

    init: function(trackingCodeURL) {
        if (this.initialized) {
            return;
        }

        this.trackingCodeURL = trackingCodeURL;

        this.createNewsletterKundenCookie();
        this.createImageDOM();

        this.initialized = true;
    }
};

export {
    trackingCode
};